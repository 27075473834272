import React from "react";
import Layout from "../../components/layout";
import "../../css/pages/auth.scss";
import AuthType from "../../components/auth/AuthType";

function AuthSigninPage(props) {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <main
        className="wrapper main-data-container"
        style={{ padding: 0, margin: 0, maxWidth: "100%", width: "100%" }}
        role="main"
        data-datocms-noindex
      >
        <AuthType type={"signin"} providers={["google", "facebook"]} />
      </main>
    </Layout>
  );
}

export default AuthSigninPage;
